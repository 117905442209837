const baseurl = {
  stage1baseurl: "https://stage.diro.live/Zuul-1.0/",
  stage2baseurl: "https://stage2.diro.live/Zuul-1.0/",
  prodbaseurl: "https://prod.dirolabs.com/Zuul-1.0/",
};

const stage2 = {
  env: "stage2",
  verifyHash: baseurl.stage2baseurl + "uploadKyc-2.0/verifyhash",
  get_lastclicked_link: baseurl.stage2baseurl + "User-2.0/getlastclickedlink",
  getverificationlink:
    baseurl.stage2baseurl + "organization-2.0/getverificationlinkpoc",
  update_session: baseurl.stage2baseurl + "User-2.0/updatesession",
  getProcessedDataFromChatGpt:
    "https://api2.diro.live/textract/getlivegptfeedback",
  logUrl: "https://api2.diro.live/logs/logs",
  sentryLogs: "https://utils.diro.live/uploadSentryLogs",
  oneshotupload: baseurl.stage2baseurl + "Oneshotupload-2.0/oneshotupload",
  feedbackurl: baseurl.stage2baseurl + "User-2.0/feedback",
  engagementCallback: "https://api2.diro.live/ube/engagement-status-upload",
  sentryDNS:"https://92bf5e7e118c6ab151ca1b08c650d2d3@o305199.ingest.us.sentry.io/4507372417318912",
  s3Upload:"https://api2.diro.live/org/api/s3uploadCapture",
  imageToPdfApiUrl:"https://proc2.diro.live/pdfOCR/image-to-pdf-converter",
}

const stage1 = {
  env: "stage1",
  verifyHash: baseurl.stage1baseurl + "uploadKyc-2.0/verifyhash",
  get_lastclicked_link: baseurl.stage1baseurl + "User-2.0/getlastclickedlink",
  getverificationlink:
    baseurl.stage1baseurl + "organization-2.0/getverificationlinkpoc",
  update_session: baseurl.stage1baseurl + "User-2.0/updatesession",
  getProcessedDataFromChatGpt:
    "https://api1.diro.live/textract/getlivegptfeedback",
  logUrl: "https://api1.diro.live/logs/logs",
  sentryLogs: "https://utils.diro.live/uploadSentryLogs",
  oneshotupload: baseurl.stage1baseurl + "Oneshotupload-2.0/oneshotupload",
  feedbackurl: baseurl.stage1baseurl + "User-2.0/feedback",
  engagementCallback: "https://api1.diro.live/ube/engagement-status-upload",
  pdfpreview: baseurl.stage1 + "Oneshotupload-2.0/previewdocument?filename=",
  sentryDNS:"https://92bf5e7e118c6ab151ca1b08c650d2d3@o305199.ingest.us.sentry.io/4507372417318912",
  imageToPdfApiUrl:"https://proc1.diro.live/pdfOCR/image-to-pdf-converter",
  origin:"https://json1.diro.live",
  s3Upload:"https://api1.diro.live/org/api/s3uploadCapture",
  imageToPdfApiUrl:"https://proc1.diro.live/pdfOCR/image-to-pdf-converter",
};

const prodeu = {
  env: "prodeu",
  verifyHash: baseurl.prodbaseurl + "uploadKyc-2.0/verifyhash",
  get_lastclicked_link: baseurl.prodbaseurl + "User-2.0/getlastclickedlink",
  getverificationlink:
    baseurl.prodbaseurl + "organization-2.0/getverificationlinkpoc",
  update_session: baseurl.prodbaseurl + "User-2.0/updatesession",
  getProcessedDataFromChatGpt:
    "https://api.diro.io/textract/getlivegptfeedback",
  logUrl: "https://api.diro.io/logs/logs",
  sentryLogs: "https://logs.diro.live/uploadSentryLogs",
  oneshotupload: baseurl.prodbaseurl + "Oneshotupload-2.0/oneshotupload",
  feedbackurl: baseurl.stage1baseurl + "User-2.0/feedback",
  engagementCallback: "https://api.diro.io/ube/engagement-status-upload",
  sentryDNS:"https://92bf5e7e118c6ab151ca1b08c650d2d3@o305199.ingest.us.sentry.io/4507372417318912",
  s3Upload:"https://api.diro.io/org/api/s3uploadCapture",
  imageToPdfApiUrl:"https://proc.diro.io/pdfOCR/image-to-pdf-converter",
};

const CDN = {
  env: "CDN",
  button: true,
  buttonId: "O.IN-h4fh6i-VtjD",
  trackId: "",

  verifyHash: baseurl.stage2baseurl + "uploadKyc-2.0/verifyhash",
  get_lastclicked_link: baseurl.stage2baseurl + "User-2.0/getlastclickedlink",
  getverificationlink:
    baseurl.stage2baseurl + "organization-2.0/getverificationlinkpoc",
  update_session: baseurl.stage2baseurl + "User-2.0/updatesession",
  getProcessedDataFromChatGpt:
    "https://api2.diro.live/textract/getlivegptfeedback",
  logUrl: "https://utils.diro.live/logs",
  sentryLogs: "https://utils.diro.live/uploadSentryLogs",
  oneshotupload: baseurl.stage2baseurl + "Oneshotupload-2.0/oneshotupload",
  feedbackurl: baseurl.stage2baseurl + "User-2.0/feedback",
  engagementCallback: "https://api2.diro.live/ube/engagement-status-upload",
  s3Upload:"https://api2.diro.live/org/api/s3uploadCapture",
  imageToPdfApiUrl:"https://proc2.diro.live/pdfOCR/image-to-pdf-converter",
};

export { stage1 };
export { prodeu  as env};
export { stage2 };
export { CDN };
