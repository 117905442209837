import axios from "axios";
import { env as environment } from "../environments";
import { CORRUPT_FILE, LIVEFEEDBACK_DATA, LIVEFEEDBACK_INVALID_DOC, LIVEFEEDBACK_START, LIVEFEEDBACK_SUCCESS, PASSWORD_PROTECTED, SOMETHING_WENT_WRONG } from "./actionTypes";
import { updateSession } from "../helpers/updateSessionHandler";

export const liveFeedback =  (file,category,sessionId,largeImage,imageToPdfConverted, subcategorySelected,documentSelected) => {

  return async (dispatch) => {
    try {
    if (category === "bank" || category === "address") {
      dispatch({ type: LIVEFEEDBACK_START });

      const Header = {
        "Content-Type": "application/json",
      };

      const liveFeedbackForm = new FormData();
      let datetime = new Date().getTime().toString();

      const key = sessionId + "-" + datetime + ".pdf";
      liveFeedbackForm.append("file", file, key);
      liveFeedbackForm.append("category", category);
      liveFeedbackForm.append("sessionid", sessionId);
      liveFeedbackForm.append("largeImage", largeImage);
      liveFeedbackForm.append("imageToPdfConverted", imageToPdfConverted);

      //reverted for now
      if(false && subcategorySelected && documentSelected){
        liveFeedbackForm.append("subCategory", JSON.stringify(subcategorySelected));
        liveFeedbackForm.append("documentCheck", JSON.stringify(documentSelected));
      }

      const response = await axios.post(
        environment.getProcessedDataFromChatGpt,
        liveFeedbackForm,
        Header
      );
      var send_livefeedback = {
        sessionid: sessionId,
        data: { livefeedback: response.data },
      };
      dispatch(updateSession(send_livefeedback,sessionId));
      dispatch({ type: LIVEFEEDBACK_DATA, payload: response?.data?.response });
    
    if (
      response.status === 200 &&
      response?.data?.response?.document_type === "Invalid"
    ) {
      // To call the wrong document popup, call the below action with the given payload
      
      dispatch({ type: LIVEFEEDBACK_INVALID_DOC, payload:true });
    }
    else if(
      response?.data?.error == true &&
      response?.data?.message?.toLowerCase().includes("password")
    ) {
      dispatch({type:PASSWORD_PROTECTED, payload:true})
    }
    else if(
      response?.data?.error == true &&
      response?.data?.message?.toLowerCase().includes("Failed to parse PDF document".toLowerCase()) || 
      response?.data?.message?.toLowerCase().includes("Data is not extracted from pdf".toLowerCase())
    ) {
      dispatch({type:CORRUPT_FILE, payload:true})
    }
    else if(response.status !== 200 ||  response?.data?.error){
      dispatch({ type: SOMETHING_WENT_WRONG, payload: true });
    }

      const chatGptResponse = response?.data;
      dispatch({ type: LIVEFEEDBACK_SUCCESS, payload: chatGptResponse });
    
    }
}catch(error){
  console.log(error)

// if(response.status === 413){
  dispatch({ type: SOMETHING_WENT_WRONG, payload: true });
// }
}
  }
}